import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#5bb35b',
        accent: '#5ab55a'
      },
      light: {
        primary: '#5bb35b',
        accent: '#5ab55a'
      }
    }
  }
});
