import VueRouter from 'vue-router';
import { Service } from '@vueent/core';
import { calculated, tracked } from '@vueent/reactive';
import isEqual from 'lodash/isEqual';

import { registerService, injectService as service } from '@/vueent';
import { api as axios } from '@/boot/axios';
import * as api from '@/api/auth';
import { create as createLoginModel } from '@/models/auth';
import { Role } from '@/models/role';
import type { Data as Account, ModelType as AccountModel } from '@/models/account';
import { create as createAccountModel } from '@/models/account';
import { AuthMode } from '@/models/auth-mode';
import { AUTH_MODE, PUBLIC_DEMO, PUBLIC_DEMO_AUTH_TOKEN, PUBLIC_DEMO_USER } from '@/constants';

import SharedService from './shared';

export interface RefreshWatcher {
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
}

export default class AuthService extends Service {
  @service(SharedService) private readonly shared!: SharedService;

  private _refreshing = false;
  @tracked private _accountModel?: AccountModel = undefined;
  private _refresherWatchers: RefreshWatcher[] = [];
  private _router?: VueRouter;

  @calculated public get authenticated() {
    return Boolean(this._accountModel);
  }

  public get account() {
    return this._accountModel;
  }

  @calculated public get isAdminOrManager() {
    return this._accountModel?.data.role === Role.admin || this._accountModel?.data.role === Role.manager;
  }

  @calculated public get isAdmin() {
    return this._accountModel?.data.role === Role.admin;
  }

  constructor() {
    super();

    const accountData =
      PUBLIC_DEMO && PUBLIC_DEMO_AUTH_TOKEN && PUBLIC_DEMO_USER
        ? { account: PUBLIC_DEMO_USER, accessToken: PUBLIC_DEMO_AUTH_TOKEN }
        : this.loadLocalData();

    if (accountData) {
      this.updateAccountModel(accountData);
    }
  }

  public getLoginModel() {
    return createLoginModel();
  }

  public async login(email: string, password: string): Promise<void> {
    let account;

    this.shared.requesting = true;

    try {
      account = await api.login({ email, password });
    } catch (e) {
      this.shared.requesting = false;

      throw e;
    }

    const accessToken = account.accessToken;

    delete account.accessToken;

    this.updateAccountModel({ account, accessToken }, true);
    this.shared.requesting = false;
  }

  public async logout(locally?: boolean): Promise<void> {
    if (!locally && AUTH_MODE === AuthMode.jwtToken) {
      try {
        await api.logout();
      } catch (error) {
        console.error('logout request returns error', error);
      }
    }

    this._accountModel?.destroy();
    this._accountModel = undefined;
    localStorage.removeItem('account');

    if (AUTH_MODE === AuthMode.token) {
      localStorage.removeItem('access_token');
      delete axios.defaults.headers.common['Authorization'];
    }

    if (this._router?.currentRoute.path !== '/login') this._router?.replace('/login');
  }

  public async refresh(topLevelError?: unknown): Promise<void> {
    const account = this.loadLocalData();

    if (account && !isEqual(this._accountModel?.data, account)) {
      this.updateAccountModel(account);

      return;
    } else if (this._refreshing) {
      await new Promise((resolve, reject) => this._refresherWatchers.push({ resolve, reject }));

      return;
    }

    if (AUTH_MODE === AuthMode.jwtToken) {
      this.setRefreshing(true);

      try {
        await api.refresh();
      } catch (error) {
        const e = topLevelError ?? error;

        this.logout(true);
        this._refresherWatchers.forEach(watcher => watcher.reject(e));
        this.setRefreshing(false);

        throw e;
      }

      this._refresherWatchers.forEach(watcher => watcher.resolve());
      this.setRefreshing(false);
    } else {
      const e = topLevelError ?? new Error('refresh operation not supported');

      this.logout(true);
      this._refresherWatchers.forEach(watcher => watcher.reject(e));

      throw e;
    }
  }

  public setRouter(router: VueRouter) {
    if (!this._router) this._router = router;
  }

  private setRefreshing(value: boolean) {
    this._refreshing = value;
    this._refresherWatchers = [];
  }

  private updateAccountModel({ account, accessToken }: { account: Account; accessToken?: string }, save = false) {
    if (save) {
      if (AUTH_MODE === AuthMode.token) localStorage.setItem('access_token', accessToken!);

      localStorage.setItem('account', JSON.stringify(account));
    }

    this._accountModel?.destroy();
    this._accountModel = createAccountModel(account);
    axios.defaults.headers.common['Authorization'] = 'Token ' + accessToken;
  }

  private loadLocalData() {
    let account: Account | undefined = undefined;

    const accountData = localStorage.getItem('account') ?? undefined;

    if (!accountData) return undefined;

    try {
      account = JSON.parse(accountData) as Account;
    } catch (e) {
      return undefined;
    }

    let accessToken: string | undefined;

    if (AUTH_MODE === AuthMode.token) {
      accessToken = localStorage.getItem('access_token') ?? undefined;

      if (!accessToken) return undefined;
    }

    return { account, accessToken };
  }
}

registerService(AuthService);
