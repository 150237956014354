import type { AxiosRequestConfig } from 'axios';

import { api as axios } from '@/boot/axios';

import * as requests from './requests';
import * as responses from './responses';

export async function login(data: requests.Login): Promise<responses.Login> {
  const response = await axios.post('/login/', data, { withCredentials: true });

  return response.data;
}

export async function logout(): Promise<void> {
  await axios.get('/logout/', { withCredentials: true, skipAuthRefresh: true } as AxiosRequestConfig<any>);
}

export async function refresh(): Promise<void> {
  await axios.get('/refresh/', { withCredentials: true, skipAuthRefresh: true } as AxiosRequestConfig<any>);
}
