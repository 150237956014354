/**
 * Backend response error.
 */
export interface BackendError {
  response: {
    data: {
      code?: number;
      text: string;
    };
  };
}

/**
 * Casts an error to BackendError type dynamically.
 *
 * @param inst - error instance
 */
export function isBackendError(inst: any): inst is BackendError {
  const i = inst as any;

  return (
    (i?.response?.data?.code === undefined || typeof i?.response?.data?.code === 'number') &&
    typeof i?.response?.data?.text === 'string'
  );
}

/**
 * Alert data/options object.
 */
export interface Alert {
  /**
   * Default alter text.
   */
  text: string;

  /**
   * Alert type (affects popup colors).
   */
  type?: 'error' | 'warning' | 'success' | 'info';

  /**
   * A function that is called when the user clicks on the popup.
   */
  action?: () => Promise<void> | void;

  /**
   * Error value.
   *
   * May contain the error code, text, HTTP response code, etc.
   */
  error?: BackendError | unknown;

  /**
   * Source key which is used to select between multiple templates with the same error code.
   */
  sourceKey?: string;

  /**
   * Template values.
   *
   * Values are placed into the decoded error message template to specify the message text.
   */
  values?: unknown[];

  /**
   * Flag that indicates the need to check the error code for 401.
   */
  checkAccess?: boolean;

  /**
   * Flag that indicates the need to check the error code for 404.
   */
  checkNotFound?: boolean;
}
