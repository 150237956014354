
import { defineComponent, computed } from 'vue';

import { useService } from '@/vueent';
import AlertsService from '@/services/alerts';

function setup() {
  const alerts = useService(AlertsService);

  const current = computed(() => alerts.current);
  const showing = computed(() => alerts.showing);
  const timeout = alerts.timeout;
  const classList = computed(() => {
    return { clickable: !!alerts.current?.action };
  });
  const color = computed(() => alerts.current?.type);
  const text = computed(() => alerts.current?.text);

  const callAction = () => alerts.callAction();
  const close = () => alerts.hide();
  const handleClosingByTimeout = (event: Event) => alerts.handleClosingByTimeout(event);

  return { current, showing, timeout, callAction, classList, close, handleClosingByTimeout, color, text };
}

export default defineComponent({ setup });
