import { Service } from '@vueent/core';
import { calculated, tracked } from '@vueent/reactive';

import { registerService } from '@/vueent';

export default class SharedService extends Service {
  @tracked public title = 'Алвес';
  @tracked private _requesting = 0;

  @calculated public get requesting() {
    return this._requesting > 0;
  }

  public set requesting(value: boolean) {
    if (value) ++this._requesting;
    else if (this._requesting > 0) --this._requesting;
  }
}

registerService(SharedService);
