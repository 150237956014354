import { watch } from 'vue';
import { Service } from '@vueent/core';
import { tracked } from '@vueent/reactive';

import { registerService } from '@/vueent';
import { decodeError } from '@/translations/errors';
import type { Alert } from '@/models/alert';
import { ALERT_TIMEOUT } from '@/constants';

export default class AlertsService extends Service {
  public readonly timeout = ALERT_TIMEOUT;

  @tracked private _current?: Alert = undefined;
  private readonly _queue: Alert[] = [];
  @tracked private _showing = false;

  public get showing() {
    return this._showing;
  }

  public get current() {
    return this._current;
  }

  constructor() {
    super();

    watch(
      () => this._showing,
      value => !value && setTimeout(() => this.showNext(), 100) // temporary solution, need to be reworked in the future
    );
  }

  public push(alert: Alert) {
    if (this.errorProcessing(alert)) {
      if (alert.error) alert.text = decodeError(alert.error, alert.text, alert.sourceKey, ...(alert.values ?? []));

      this._queue.push(alert);
    }

    if (!this._showing) this.showNext();
  }

  public hide() {
    this._showing = false;
  }

  public showNext() {
    const length = this._queue.length;
    const temp = this._queue.shift();

    this._current = temp ?? undefined;

    if (length) this._showing = true;
  }

  public callAction() {
    if (this.current?.action) this.current.action();

    this.hide();
  }

  public handleClosingByTimeout(event: Event) {
    if (!event) this.hide();
  }

  /**
   * Checks an alert object for error codes.
   *
   * If alert object have checkAccess or checkNotFound flag
   * and error status code matches 401 or 404
   * function will return false,
   * which means you don't need to display alert.
   *
   * @param alert - Alert object to check
   */
  private errorProcessing(alert: Alert) {
    const error = alert.error as any;

    switch (error?.response?.status) {
      case 401:
        return !alert?.checkAccess;
      case 404:
        return !alert?.checkNotFound;
      default:
        return true;
    }
  }
}

registerService(AlertsService);
