import {
  Base,
  BaseModel,
  CreateFunc,
  DestroyFunc,
  mix,
  mixRollback,
  mixSave,
  mixValidate,
  Options,
  PatternAssert,
  Rollback,
  RollbackPrivate,
  Save,
  SaveOptions,
  SavePrivate,
  UpdateFunc,
  Validate,
  ValidateOptions,
  ValidatePrivate,
  ValidationBase
} from '@vueent/mix-models';
import { simplify, v9s } from 'v9s';
import { email } from 'v9sx';

import { name } from '@/utilities/validators';

import { Role } from './role';

export interface Data {
  id: number;
  email: string;
  name: string;
  role: Role;
  blocked: boolean;
  password?: string;
}

export type EncodedData = Data;

export function makeInitialData(): Data {
  return {
    id: 0,
    email: '',
    name: '',
    role: Role.user,
    blocked: false,
    password: undefined
  };
}

class DataModel extends BaseModel<Data> {}

const rollbackMask = { email: true, name: true, role: true, blocked: true, password: true } as const;

const validations = {
  email: simplify(
    v9s<string>()
      .minLength(1, 'Введите E-mail.')
      .maxLength(255, 'Превышена максимальная длина поля.')
      .use(email, 'Поле должно соответствовать формату E-mail.')
  ),
  name: simplify(
    v9s<string>()
      .minLength(1, 'Введите имя пользователя.')
      .maxLength(255, 'Превышена максимальная длина поля.')
      .use(name, 'Удалите недопустимые символы.')
  ),
  password: simplify(
    v9s<string>()
      .minLength(1, 'Введите пароль.')
      .minLength(6, 'Пароль должен содержать не менее 6 символов.')
      .maxLength(32, 'Превышена максимально допустимая длина пароля.')
  )
} as const;

export type Validations = PatternAssert<typeof validations, Data>;

export type BlockFunc = (id: unknown) => Promise<void>;
export type UnblockFunc = (id: unknown) => Promise<void>;

export interface Blocker {
  block(): Promise<void>;
  unblock(): Promise<void>;
}

export interface BlockerOptions {
  readonly mixinType: 'block';
  readonly block?: BlockFunc;
  readonly unblock?: UnblockFunc;
}

export interface Model extends DataModel, SavePrivate<Data>, RollbackPrivate<Data>, ValidatePrivate<Validations>, Blocker {}

export class Model extends mix<Data, typeof DataModel>(
  DataModel,
  mixSave(),
  mixRollback(rollbackMask),
  mixValidate<Validations>(validations)
) {
  private _block: BlockFunc;
  private _unblock: UnblockFunc;

  constructor(initialData?: Data, react = true, ...options: Options[]) {
    super('id', initialData ?? makeInitialData(), react, ...options);

    if (initialData?.id && initialData.id > 0) this._flags.new = false; // reset the `new` flag

    let block: BlockFunc | undefined;
    let unblock: UnblockFunc | undefined;

    options.some(option => {
      if (option.mixinType === 'block') {
        const blockerOptions = option as BlockerOptions;

        block = blockerOptions.block;
        unblock = blockerOptions.unblock;

        return true;
      }

      return false;
    });

    this._block = block ? block : async () => {};
    this._unblock = unblock ? unblock : async () => {};
  }

  async block(): Promise<void> {
    await this._block((this.data as unknown as Record<string, unknown>)[this._idKey]);
    this.data.blocked = true;
    this._flags.dirty = false;
    this.v.reset();
  }

  async unblock(): Promise<void> {
    await this._unblock((this.data as unknown as Record<string, unknown>)[this._idKey]);
    this.data.blocked = false;
    this._flags.dirty = false;
    this.v.reset();
  }
}

export type ModelType = Base<Data> & Save & Rollback & Validate<Validations> & Blocker;

export function create(
  basicData?: Data,
  react = true,
  params: {
    validations?: ValidationBase;
    create?: CreateFunc<Data>;
    update?: UpdateFunc<Data>;
    destroy?: DestroyFunc<Data>;
    block?: BlockFunc;
    unblock?: UnblockFunc;
  } = {}
) {
  const options: Array<ValidateOptions | SaveOptions<Data> | BlockerOptions> = [];

  if (params.validations) options.push({ mixinType: 'validate', validations: params.validations });
  if (params.create || params.update || params.destroy)
    options.push({ mixinType: 'save', create: params.create, update: params.update, destroy: params.destroy });
  if (params.block || params.unblock) options.push({ mixinType: 'block', block: params.block, unblock: params.unblock });

  return new Model(basicData, react, ...options) as ModelType;
}
