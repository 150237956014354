
import { defineComponent, ref, computed } from 'vue';

import { useController } from '@/vueent';
import Alert from '@/components/alert.vue';

import ConfirmationsWrapper from './components/confirmations/wrapper.vue';
import AppController from './app';
import {
  VERSION,
  COPY_YEAR,
  ENABLE_HARVESTING_MODULE,
  ENABLE_SEEDING_MODULE,
  ENABLE_GREENERY_MODULE,
  SINGLE_PROCESS_MODE,
  PUBLIC_DEMO
} from './constants';

function setup() {
  const controller = useController(AppController);

  const drawer = ref(false);
  const clipped = ref(false);

  const title = computed(() => controller.title);
  const accountName = computed(() => controller.accountName);

  const items = computed(() => {
    const disabled = controller.requesting || !controller.authenticated;
    const menu: Array<{ title: string; route: string; disabled?: boolean; icon?: string; delim?: true }> = [
      { title: 'Главная', route: '/', disabled }
    ];

    if (controller.controlAllowed && !PUBLIC_DEMO) {
      menu.push({ title: 'Учетные записи', route: '/accounts', disabled }, { title: 'Настройки', route: '/settings', disabled });
    }

    menu.push(
      { title: 'Весовые терминалы', route: '/terminals', disabled },
      { title: 'Комбайнеры', route: '/combine-operators', disabled }
    );

    if (!PUBLIC_DEMO) menu.push({ title: 'Приложения', route: '/applications', disabled });

    if (!SINGLE_PROCESS_MODE && (ENABLE_SEEDING_MODULE || ENABLE_HARVESTING_MODULE || ENABLE_GREENERY_MODULE))
      menu.push({ title: '', route: '', delim: true });

    if (!SINGLE_PROCESS_MODE && ENABLE_SEEDING_MODULE) menu.push({ title: 'Посевная', route: '/seeding', disabled });

    if (!SINGLE_PROCESS_MODE && ENABLE_HARVESTING_MODULE) menu.push({ title: 'Уборка', route: '/harvesting', disabled });

    if (!SINGLE_PROCESS_MODE && ENABLE_GREENERY_MODULE) menu.push({ title: 'Уборка зелени', route: '/greenery', disabled });

    menu.push(
      { title: '', route: '', delim: true },
      { title: 'Журнал событий', route: '/actions-journals', disabled },
      { title: 'Архив масс', route: '/weight-archive', disabled }
    );

    return menu;
  });

  const breadcrumbs = computed(() => controller.breadCrumbs);

  const logout = () => controller.logout();

  const authenticated = computed(() => controller.authenticated);

  return { drawer, clipped, items, title, accountName, breadcrumbs, logout, authenticated, VERSION, COPY_YEAR, PUBLIC_DEMO };
}

export default defineComponent({ components: { Alert, ConfirmationsWrapper }, setup });
