import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { useService } from '@/vueent';
import { API_ROOT, PUBLIC_PATH } from '@/constants';
import AuthService from '@/services/auth';

const api = axios.create({ baseURL: API_ROOT });
const resources = axios.create({ baseURL: PUBLIC_PATH });

/**
 * Refreshes access token if it is expired.
 *
 * @async
 *
 * @param {object} failedRequest
 */
const refreshAuthLogic = async (failedRequest: { response: { config: { headers: Record<string, string> } } }) => {
  await useService(AuthService).refresh(failedRequest);
};

// register the refresh interceptor of tokens
createAuthRefreshInterceptor(api, refreshAuthLogic);

export { api, resources };
