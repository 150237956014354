import { Controller } from '@vueent/core';
import { calculated } from '@vueent/reactive';

import { registerController, injectService as service } from '@/vueent';

import SharedService from '@/services/shared';
import AuthService from '@/services/auth';
import BreadcrumbsService from './services/breadcrumbs';
import router from '@/router';

export default class AppController extends Controller {
  @service(SharedService) private shared!: SharedService;
  @service(AuthService) private readonly auth!: AuthService;
  @service(BreadcrumbsService) private readonly breadcrumbs!: BreadcrumbsService;

  @calculated public get title() {
    return this.shared.title;
  }

  @calculated public get requesting() {
    return this.shared.requesting;
  }

  @calculated public get authenticated() {
    return this.auth.authenticated;
  }

  public get controlAllowed() {
    return this.auth.isAdminOrManager;
  }

  @calculated public get accountName() {
    return this.auth.account?.data.name;
  }

  @calculated public get breadCrumbs() {
    return this.breadcrumbs.breadcrumbs;
  }

  constructor() {
    super();

    this.auth.setRouter(router);
  }

  public logout() {
    this.auth.logout();
  }
}

registerController(AppController);
