import Vue from 'vue';
import App from './app.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import '@mdi/font/scss/materialdesignicons.scss';
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss';
import './boot/axios';

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
