
import { defineComponent, PropType } from 'vue';

import type { Confirmation, Action } from '@/models/confirmation';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<Confirmation>,
      required: true
    }
  },

  setup(props) {
    const submitAction = props.value.actions.find(a => a.mode === 'primary');
    const closeAction = props.value.actions.find(a => a.mode === 'close');

    const title = props.value.title
      ? typeof props.value.title === 'string'
        ? props.value.title
        : props.value.title()
      : undefined;
    const text = props.value.text ? (typeof props.value.text === 'string' ? props.value.text : props.value.text()) : undefined;

    const getColor = (action: Action) => action.color || (action.mode !== 'close' && action.mode) || undefined;
    const handleSubmit = () => props.value.close(submitAction?.value ?? '');
    const handleOutsideClick = props.value.persistent ? () => {} : () => props.value.close(closeAction?.value ?? '');
    const handleButtonClick = (action: Action) => action.mode !== 'primary' && props.value.close(action.value);

    return { title, text, handleSubmit, handleOutsideClick, getColor, handleButtonClick };
  }
});
