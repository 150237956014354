import { Service } from '@vueent/core';
import { tracked, calculated } from '@vueent/reactive';
import { Location } from 'vue-router';

import { registerService } from '@/vueent';
import router from '@/router';

export type StrictLocation = Omit<Location, 'path'> & { path: string };
export type BreadcrumbsItem = StrictLocation & { text: string };

export default class BreadcrumbsService extends Service {
  @tracked private _locations: BreadcrumbsItem[] = [];

  @calculated public get breadcrumbs() {
    return this._locations.map((l, i, locations) => ({
      text: l.text,
      disabled: i >= locations.length - 1,
      to: l,
      exact: true
    }));
  }

  public setLocation(location: StrictLocation) {
    const locations: BreadcrumbsItem[] = [];

    for (let i = 1; i < this._locations.length; ++i) {
      const l = this._locations[i];

      if ((location.path !== l.path && location.path.indexOf(l.path)) === 0) locations.push(l);
      else break;
    }

    const pathPrefix = locations.length ? locations[locations.length - 1].path : '';
    const cuttedPath = locations.length ? location.path.replace(locations[locations.length - 1].path, '') : location.path;
    const splitted = cuttedPath.split('/');

    if (splitted.length && splitted[0] === '') splitted.splice(0, 1);

    for (let i = 0; i < splitted.length - 1; ++i) {
      const path = pathPrefix + '/' + splitted.slice(0, i + 1).join('/');

      if (path === '/') continue;

      locations.push({ path, text: (router.match({ path }).meta?.breadcrumbs as string | undefined) ?? '???' });
    }

    const route = router.match(location);
    const text = (route.meta?.breadcrumbs as string | undefined) ?? '???';

    this._locations = [{ path: '/', text: 'Главная' }, ...locations, { ...location, text }];
  }
}

registerService(BreadcrumbsService);
