export const textRegex =
  /^[ a-zA-Z0-9абвгдеёжзийклмнопрстуфхцчшщъыьэюяАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ.,:;|~/\\*&`'"?!@$%^()<>{}[\]-]*$/;
export const nameRegex = /^[ a-zA-Z0-9абвгдеёжзийклмнопрстуфхцчшщъыьэюяАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ.,&'"-]*$/;
export const physicalAddressRegex = /^[0-9a-f]{2}:[0-9a-f]{2}:[0-9a-f]{2}:[0-9a-f]{2}:[0-9a-f]{2}:[0-9a-f]{2}$/;
export const partialUuidRegex = /^[0-9a-f-]*$/;
export const networkInterfaceRegex = /^[a-z]{2}[0-9a-z]+$/;
const ipv4Regex = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
const netmaskLeadingOnes = '(255|254|252|248|240|224|192|128|0+)';
const netmaskAllOnes = '(255\\.)';
const netmaskRegex = new RegExp(
  '^((' +
    netmaskAllOnes +
    '{3}' +
    netmaskLeadingOnes +
    ')|' +
    '(' +
    netmaskAllOnes +
    '{2}' +
    netmaskLeadingOnes +
    '\\.0+)|' +
    '(' +
    netmaskAllOnes +
    netmaskLeadingOnes +
    '(\\.0+){2})|' +
    '(' +
    netmaskLeadingOnes +
    '(\\.0+){3}))$'
);

export function text(value: string) {
  return textRegex.test(value);
}

export function name(value: string) {
  return nameRegex.test(value);
}

export function physicalAddress(value: string) {
  return !value || physicalAddressRegex.test(value);
}

export function partialUuid(value: string) {
  return !value || partialUuidRegex.test(value);
}

export function networkInterface(value: string) {
  return networkInterfaceRegex.test(value);
}

export function ipv4(value: string) {
  return ipv4Regex.test(value);
}

export function netmask(value: string) {
  return netmaskRegex.test(value);
}
