
import { defineComponent, computed } from 'vue';

import { useService } from '@/vueent';
import ConfirmationsService from '@/services/confirmations';

import Dialog from './dialog.vue';

function setup() {
  const confirmations = useService(ConfirmationsService);

  const queries = computed(() => confirmations.queries);

  return { queries };
}

export default defineComponent({ components: { Dialog }, setup });
