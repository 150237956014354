import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Route, RawLocation } from 'vue-router';

import { useService } from '@/vueent';
import AuthService from '@/services/auth';

import {
  BASE_URI,
  ENABLE_HARVESTING_MODULE,
  ENABLE_SEEDING_MODULE,
  ENABLE_GREENERY_MODULE,
  SINGLE_PROCESS_MODE,
  PUBLIC_DEMO
} from './constants';

interface NextMW {
  (to?: RawLocation | false | ((vm: Vue) => unknown) | void): void;
}

function ifNotAuthenticated(to: Route, from: Route, next: NextMW) {
  const authenticated = useService(AuthService).authenticated;

  authenticated ? next('/') : next();
}

function ifAuthenticated(to: Route, from: Route, next: NextMW) {
  const authenticated = useService(AuthService).authenticated;

  authenticated ? next() : next('/login');
}

const routes: RouteConfig[] = [
  {
    path: '/terminals',
    component: () => import('@/routes/terminals/index.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      breadcrumbs: 'Весовые терминалы'
    },
    children: [
      {
        path: '',
        component: () => import('@/routes/terminals/list.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Весовые терминалы'
        }
      },
      {
        path: 'add',
        component: () => import('@/routes/terminals/add.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Добавление'
        }
      },
      {
        path: ':id',
        component: () => import('@/routes/terminals/edit.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Редактирование'
        }
      }
    ]
  },
  {
    path: '/combine-operators',
    component: () => import('@/routes/combine-operators/index.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      breadcrumbs: 'Комбайнеры'
    },
    children: [
      {
        path: '',
        component: () => import('@/routes/combine-operators/list.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Комбайнеры'
        }
      },
      {
        path: 'add',
        component: () => import('@/routes/combine-operators/add.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Добавление'
        }
      },
      {
        path: ':id',
        component: () => import('@/routes/combine-operators/edit.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Редактирование'
        }
      }
    ]
  },
  {
    path: '/actions-journals',
    component: () => import('@/routes/actions-journals.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      breadcrumbs: 'Журнал событий'
    }
  },
  {
    path: '/weight-archive',
    component: () => import('@/routes/weight-archive.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      breadcrumbs: 'Архив масс'
    }
  }
];

if (!PUBLIC_DEMO) {
  routes.push(
    {
      path: '/accounts',
      component: () => import('@/routes/accounts/index.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        breadcrumbs: 'Учетные записи'
      },
      children: [
        {
          path: '',
          component: () => import('@/routes/accounts/list.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            breadcrumbs: 'Учетные записи'
          }
        },
        {
          path: 'add',
          component: () => import('@/routes/accounts/add.vue'),
          beforeEnter: ifAuthenticated,
          meta: {
            breadcrumbs: 'Добавление'
          }
        }
      ]
    },
    {
      path: '/login',
      component: () => import('@/routes/login.vue'),
      beforeEnter: ifNotAuthenticated,
      meta: {
        breadcrumbs: 'Вход'
      }
    },
    {
      path: '/settings',
      component: () => import('@/routes/settings.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        breadcrumbs: 'Настройки'
      }
    },
    {
      path: '/applications',
      component: () => import('@/routes/applications.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        breadcrumbs: 'Приложения'
      }
    }
  );
}

if (!SINGLE_PROCESS_MODE) {
  routes.push({
    path: '/',
    component: () => import('@/routes/home.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      breadcrumbs: 'Главная'
    }
  });
}

if (ENABLE_SEEDING_MODULE) {
  routes.push({
    path: '/seeding',
    alias: SINGLE_PROCESS_MODE ? '/' : undefined,
    component: () => import('@/routes/seeding/index.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      breadcrumbs: 'Посевная'
    },
    children: [
      {
        path: '',
        component: () => import('@/routes/seeding/list.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Посевная'
        }
      },
      {
        path: 'fields',
        component: () => import('@/routes/seeding/fields/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Поля'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/seeding/fields/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Поля'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/seeding/fields/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/seeding/fields/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование'
            }
          }
        ]
      },
      {
        path: 'seeders',
        component: () => import('@/routes/seeding/seeders/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Сеялки'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/seeding/seeders/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Сеялки'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/seeding/seeders/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/seeding/seeders/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование'
            }
          }
        ]
      },
      {
        path: 'providers',
        component: () => import('@/routes/seeding/providers/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Пункты отпуска'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/seeding/providers/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Пункты отпуска'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/seeding/providers/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление пункта отпуска посевной'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/seeding/providers/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование пункта отпуска посевной'
            }
          }
        ]
      },
      {
        path: 'reports',
        component: () => import('@/routes/seeding/reports/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/seeding/reports/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/seeding/reports/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-by-terminal',
        component: () => import('@/routes/seeding/reports-by-terminal/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по терминалу'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/seeding/reports-by-terminal/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по терминалу'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/seeding/reports-by-terminal/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-to-seeder',
        component: () => import('@/routes/seeding/reports-to-seeder/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по сеялке'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/seeding/reports-to-seeder/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по сеялке'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/seeding/reports-to-seeder/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-by-provider',
        component: () => import('@/routes/seeding/reports-by-provider/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по пункту отпуска'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/seeding/reports-by-provider/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по пункту отпуска'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/seeding/reports-by-provider/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      }
    ]
  });
}

if (ENABLE_HARVESTING_MODULE) {
  routes.push({
    path: '/harvesting',
    alias: SINGLE_PROCESS_MODE ? '/' : undefined,
    component: () => import('@/routes/harvesting/index.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      breadcrumbs: 'Уборка'
    },
    children: [
      {
        path: '',
        component: () => import('@/routes/harvesting/list.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Уборка'
        }
      },
      {
        path: 'fields',
        component: () => import('@/routes/harvesting/fields/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Поля'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/harvesting/fields/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Поля'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/harvesting/fields/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/harvesting/fields/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование'
            }
          }
        ]
      },
      {
        path: 'harvesters',
        component: () => import('@/routes/harvesting/harvesters/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Комбайны'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/harvesting/harvesters/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Комбайны'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/harvesting/harvesters/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/harvesting/harvesters/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование'
            }
          }
        ]
      },
      {
        path: 'receivers',
        component: () => import('@/routes/harvesting/receivers/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Точки приема'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/harvesting/receivers/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Точки приема'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/harvesting/receivers/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/harvesting/receivers/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование'
            }
          }
        ]
      },
      {
        path: 'reports',
        component: () => import('@/routes/harvesting/reports/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/harvesting/reports/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/harvesting/reports/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-by-terminal',
        component: () => import('@/routes/harvesting/reports-by-terminal/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по терминалу'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/harvesting/reports-by-terminal/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по терминалу'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/harvesting/reports-by-terminal/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-by-harvester',
        component: () => import('@/routes/harvesting/reports-by-harvester/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по комбайну'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/harvesting/reports-by-harvester/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по комбайну'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/harvesting/reports-by-harvester/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-to-receiver',
        component: () => import('@/routes/harvesting/reports-to-receiver/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по точке приема'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/harvesting/reports-to-receiver/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по точке приема'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/harvesting/reports-to-receiver/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-by-combine-operator',
        component: () => import('@/routes/harvesting/reports-by-combine-operator/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по комбайнеру'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/harvesting/reports-by-combine-operator/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по комбайнеру'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/harvesting/reports-by-combine-operator/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'report-by-field',
        component: () => import('@/routes/harvesting/report-by-field.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по полям'
        }
      }
    ]
  });
}

if (ENABLE_GREENERY_MODULE) {
  routes.push({
    path: '/greenery',
    alias: SINGLE_PROCESS_MODE ? '/' : undefined,
    component: () => import('@/routes/greenery/index.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      breadcrumbs: 'Уборка зелени'
    },
    children: [
      {
        path: '',
        component: () => import('@/routes/greenery/list.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Уборка зелени'
        }
      },
      {
        path: 'fields',
        component: () => import('@/routes/greenery/fields/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Поля'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/greenery/fields/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Поля'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/greenery/fields/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/greenery/fields/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование'
            }
          }
        ]
      },
      {
        path: 'harvesters',
        component: () => import('@/routes/greenery/harvesters/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Комбайны'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/greenery/harvesters/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Комбайны'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/greenery/harvesters/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/greenery/harvesters/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование'
            }
          }
        ]
      },
      {
        path: 'receivers',
        component: () => import('@/routes/greenery/receivers/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Точки приема'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/greenery/receivers/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Точки приема'
            }
          },
          {
            path: 'add',
            component: () => import('@/routes/greenery/receivers/add.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Добавление'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/greenery/receivers/edit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Редактирование'
            }
          }
        ]
      },
      {
        path: 'reports',
        component: () => import('@/routes/greenery/reports/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/greenery/reports/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/greenery/reports/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-by-terminal',
        component: () => import('@/routes/greenery/reports-by-terminal/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по терминалу'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/greenery/reports-by-terminal/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по терминалу'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/greenery/reports-by-terminal/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-by-harvester',
        component: () => import('@/routes/greenery/reports-by-harvester/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по комбайну'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/greenery/reports-by-harvester/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по комбайну'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/greenery/reports-by-harvester/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      },
      {
        path: 'reports-to-receiver',
        component: () => import('@/routes/greenery/reports-to-receiver/index.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          breadcrumbs: 'Отчеты по точке приема'
        },
        children: [
          {
            path: '',
            component: () => import('@/routes/greenery/reports-to-receiver/list.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Отчеты по точке приема'
            }
          },
          {
            path: ':id',
            component: () => import('@/routes/greenery/reports-to-receiver/details.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
              breadcrumbs: 'Детали'
            }
          }
        ]
      }
    ]
  });
}

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  base: BASE_URI
});

export default router;
