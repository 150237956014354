import {
  Base,
  BaseModel,
  mix,
  Rollback,
  mixRollback,
  Validate,
  mixValidate,
  RollbackPrivate,
  ValidatePrivate,
  PatternAssert
} from '@vueent/mix-models';
import { v9s, simplify } from 'v9s';
import { email } from 'v9sx';

export interface Data {
  email: string;
  password: string;
}

class DataModel extends BaseModel<Data> {}

const rollbackMask = {
  email: true,
  password: true
} as const;

export const validations = {
  email: simplify(v9s<string>().minLength(1, 'Введите E-mail').use(email, 'Поле должно соответствовать формату E-mail.')),
  password: simplify(v9s<string>().minLength(1, 'Введите пароль.'))
} as const;

export type Validations = PatternAssert<typeof validations, Data>;

export interface Model extends DataModel, RollbackPrivate<Data>, ValidatePrivate<Validations> {}

export class Model extends mix<Data, typeof DataModel>(
  DataModel,
  mixRollback(rollbackMask),
  mixValidate<Validations>(validations)
) {
  constructor(initialData?: Data, react = true) {
    super('name', initialData ?? { email: '', password: '' }, react);
  }
}

export type ModelType = Base<Data> & Rollback & Validate<Validations>;

export function create(basicData?: Data, react = true) {
  return new Model(basicData, react) as ModelType;
}
