import { isBackendError } from '@/models/alert';

export const errorCodes: Record<string, Record<string, string> | string> = {
  0: 'Внутренняя ошибка сервера.',
  2: 'Недостаточно прав доступа.',
  9: 'Неверный логин или пароль.'
};

/**
 * Renders an error code template.
 *
 * Error code string may contain template references with a leading `$`.
 * For example, the template 'Could not load a loan application #${0}'
 * and the values list `[22]` will be rendered to
 * 'Could not load a loan application #22'.
 *
 * ATTENTION: Curly braces are required.
 *
 * @param template - template string
 * @param values - a list of values which will be placed into the template
 */
function render(template: string, ...values: unknown[]) {
  let result = template;

  for (let i = 0; i < values.length; ++i)
    result = result.replace(new RegExp('\\$\\{' + String(i) + '\\}', 'g'), String(values[i]));

  return result;
}

/**
 * Decodes an API response error.
 *
 * @param error - error data
 * @param def - default message text
 * @param sourceKey - template key which is used to select a source-specified template
 * @param values - template values list
 */
export function decodeError(error: unknown, def: string, sourceKey = 'def', ...values: unknown[]) {
  if (!isBackendError(error)) return def;

  if (error.response.data.code === null || error.response.data.code === undefined)
    return `${def}. (Текст ошибки: "${error.response.data.text}")`;

  const tpl = errorCodes[String(error.response.data.code)];
  const templateString: string | undefined = tpl && typeof tpl === 'object' ? tpl[sourceKey] : tpl;

  return templateString
    ? `${render(templateString, ...values)} (Код ошибки: "${error.response.data.code}")`
    : `${def} (Код ошибки: "${error.response.data.code}")`;
}
