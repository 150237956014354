import dayjs from 'dayjs';

import { AuthMode } from './models/auth-mode';
import { Role } from './models/role';
import type { Data as Account } from './models/account';

export const ABORT_TIMEOUT = Number(process.env.VUE_APP_ABORT_TIMEOUT);
export const ALERT_TIMEOUT = Number(process.env.VUE_APP_ALERT_TIMEOUT);
export const API_ROOT = process.env.VUE_APP_API_ROOT
  ? String(process.env.VUE_APP_API_ROOT)
  : `${location.protocol}//${location.hostname}:${Number(process.env.VUE_APP_API_DEFAULT_PORT)}`;
export const BASE_URI = process.env.VUE_APP_BASE_URI ? String(process.env.VUE_APP_BASE_URI) : '';

export const ENABLE_HARVESTING_MODULE = Boolean(Number(process.env.VUE_APP_ENABLE_HARVESTING_MODULE));
export const ENABLE_SEEDING_MODULE = Boolean(Number(process.env.VUE_APP_ENABLE_SEEDING_MODULE));
export const ENABLE_GREENERY_MODULE = Boolean(Number(process.env.VUE_APP_ENABLE_GREENERY_MODULE));
export const COPY_YEAR = String(process.env.VUE_APP_COPY_YEAR);
export const AUTHOR_NAME = String(process.env.VUE_APP_AUTHOR_NAME);
export const VERSION = String(process.env.VUE_APP_VERSION);
export const AUTH_MODE = (() => {
  switch (process.env.VUE_APP_AUTH_MODE) {
    case 'token':
      return AuthMode.token;
    default:
      return AuthMode.jwtToken;
  }
})();
export const PRINT_DATETIME_FORMAT = process.env.VUE_APP_PRINT_DATETIME_FORMAT
  ? String(process.env.VUE_APP_PRINT_DATETIME_FORMAT)
  : 'DD.MM.YYYY HH:mm';
export const PUBLIC_PATH = process.env.VUE_APP_PUBLIC_PATH ? String(process.env.VUE_APP_PUBLIC_PATH) : undefined;
export const MAPS_HOST = process.env.VUE_APP_MAPS_HOST ? String(process.env.VUE_APP_MAPS_HOST) : '';
export const SINGLE_PROCESS_MODE =
  Number(ENABLE_HARVESTING_MODULE) + Number(ENABLE_SEEDING_MODULE) + Number(ENABLE_GREENERY_MODULE) < 2;
export const SHOW_DRAWBAR_PRESSURE = Boolean(Number(process.env.VUE_APP_SHOW_DRAWBAR_PRESSURE ?? 0));
export const SHOW_ANGLES = Boolean(Number(process.env.VUE_APP_SHOW_ANGLES ?? 0));
export const APP_LINK_PREFIX = process.env.VUE_APP_APP_LINK_PREFIX ? String(process.env.VUE_APP_APP_LINK_PREFIX) : '';
export const PUBLIC_DEMO = Boolean(Number(process.env.VUE_APP_PUBLIC_DEMO ?? 0));
export const PUBLIC_DEMO_AUTH_TOKEN = process.env.VUE_APP_PUBLIC_DEMO_AUTH_TOKEN
  ? String(process.env.VUE_APP_PUBLIC_DEMO_AUTH_TOKEN)
  : '';

export const PUBLIC_DEMO_USER: Account = {
  id: process.env.VUE_APP_PUBLIC_DEMO_USER_ID ? Number(process.env.VUE_APP_PUBLIC_DEMO_USER_ID) : 0,
  name: process.env.VUE_APP_PUBLIC_DEMO_USER_NAME ? String(process.env.VUE_APP_PUBLIC_DEMO_USER_NAME) : '',
  email: process.env.VUE_APP_PUBLIC_DEMO_USER_EMAIL ? String(process.env.VUE_APP_PUBLIC_DEMO_USER_EMAIL) : '',
  role: (() => {
    if (!process.env.VUE_APP_PUBLIC_DEMO_USER_ROLE) return Role.user;

    const roleCode = Number(process.env.VUE_APP_PUBLIC_DEMO_USER_ROLE);

    switch (roleCode) {
      case Role.admin:
        return Role.admin;
      case Role.manager:
        return Role.manager;
      default:
        return Role.user;
    }
  })(),
  blocked: false,
  password: undefined
} as const;

export const MIN_DATETIME = process.env.VUE_APP_MIN_DATETIME ? dayjs(String(process.env.VUE_APP_MIN_DATETIME)) : undefined;
export const MAX_DATETIME = process.env.VUE_APP_MAX_DATETIME ? dayjs(String(process.env.VUE_APP_MAX_DATETIME)) : undefined;
export const START_DATETIME = process.env.VUE_APP_START_DATETIME ? dayjs(String(process.env.VUE_APP_START_DATETIME)) : undefined;
export const MIN_DATE = MIN_DATETIME?.format('YYYY-MM-DD');
export const MAX_DATE = MAX_DATETIME?.format('YYYY-MM-DD');
