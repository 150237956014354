import { Service } from '@vueent/core';
import { tracked } from '@vueent/reactive';

import { registerService } from '@/vueent';
import { Confirmation, Options } from '@/models/confirmation';

export default class ConfirmationsService extends Service {
  @tracked private readonly _queries: Confirmation[] = [];
  private _counter = 0;

  public get queries() {
    return this._queries;
  }

  public show(options: Options): Promise<string> {
    return new Promise<string>(resolve => {
      const uid = ++this._counter;
      const close = this.close.bind(this, uid, resolve);
      const confirmation: Confirmation = { ...options, uid, close };

      this._queries.push(confirmation);
    });
  }

  public close(uid: number, resolve: (value: string) => void, value: string) {
    const index = this._queries.findIndex(q => q.uid === uid);

    if (index === -1) return;

    resolve(value);

    this._queries.splice(index, 1);
  }
}

registerService(ConfirmationsService);
